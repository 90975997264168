#contact__section {
  background: $contact_bg;
  color: $contact_text;
  width: 100%;

  .contact__container {
      max-width: 760px;
      padding: 80px 40px;
      margin: 0 auto;
      @media only screen and (max-width: 300px) {
        padding: 20px 20px;
      }
  }
}
.contact__header {
  padding-bottom: 20px;
  display: block;
}
.contact__title {
  text-align: center;
  line-height: 1.8;
  text-transform: uppercase;
  display: block;
}
.contact__text {
  text-align: center;
  line-height: 1.8;
  display: block;
}

.contact__quick {
  text-align: center;
  //padding: 50px 0;
  display: block;
  @media only screen and (max-width: 700px) {
    padding: 0;
    margin: 20px 0;
  }

  @media only screen and (max-width: 400px) {
    padding: 0;
    margin: 0;
  }

}
.contact__mail,
.contact__tel {
  color: #fff;
  text-align: center;
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 1.6;
  padding: 0 20px;
}

.contact__form--header {
  text-align: center;
  text-transform: uppercase;
}

  input[type="checkbox"]#reg {
    visibility: hidden;
    padding: 0;
    margin: 0;
    font-size: 1px;
}

  input[type="checkbox"]#agree {
    display:none;
}
  input[type="checkbox"] + label:before {
    content:"☐";
    font-size: 30px;
}
  input[type="checkbox"]:checked + label:before {
    content:"☑";
    text-decoration: none;
}
  label.for_agree {
    display: inline-block;
    text-align: left;
    line-height: 1.4;
    font-size: 14px;
    margin-bottom: 20px;
    cursor: pointer;
}
  label.for_agree:hover {
    color: #fff;
}
  label.for_agree::before:hover {
    text-decoration: none;
    border: 0;
  }


#formanswer {
  line-height: 2;
  margin: 20px 0 30px 0 ;


}
.erorr_answer {
  color: #000;
  padding: 20px;
  background: #fff;
}
.success_answer {
  color: #000;
  padding: 20px;
  background: #fff;
}

#agree_cb {
  //padding: 10px;
}

$label_input_focus_color: #fff;
$label_input_color: #fff;
$label_input_text_color: #fff;
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid $label_input_color;
  outline: 0;
  font-size: 1rem;
  color: $label_input_text_color;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1rem;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: $label_input_color;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 0.85rem;
    color: $label_input_color;
  }
  padding-bottom: 6px;
}
/* reset input */
.form__field{
  &:required,&:invalid { box-shadow:none; }
}

#message {
  height: 40px;
  display: block;
  overflow: hidden;
  resize: none;
}
.form_buttons {
  display: block;
  text-align: center;
}
input[type=button], input[type=submit], input[type=reset] {
  display: inline-block;
  background: transparent;
  color:#fff;
  border: 1px solid #fff;
  width: auto;
  height: auto;
  padding: 10px 30px;
  font-size: 1rem;
  -webkit-appearance: none;
  margin: 0px 40px 0px 0;
  @media only screen and (max-width: 600px) {
    margin: 20px 10px;
  }
}
input[type=submit]:hover, input[type=reset]:hover {
  background: #fff;
  color: $contact_bg;
  transition: all .2s ease-in-out;
}

// quick contact icon

a {
  text-decoration: none;
  color: inherit;
}
.phone_wrapper,
.mail_wrapper {
  display: block;
}


.cta {
  position: relative;
  padding: 3px 0;
  transition: all 0.2s ease;
}
.cta:hover {
  border-bottom: 1px solid #fff;
  padding: 3px 0;
}


.form_error_class {

}

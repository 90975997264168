
footer {
  width: 100%;
  height: auto;
  background: $footer__bg;
  text-align: center;
  padding: 40px 0;
  .footer-wrapper {
    display:flex;
    align-items:center;

    .socialmedialink {
      order: 3;
      flex-basis:33%;
      text-align: right;
      padding-right: 40px;
      a img {
        width: 30px;
        height: auto;
        transition: transform .5s;
      }
      a:hover img {
        transform: scale(1.3);
      }
    }
    .author {
      order: 2;
      padding: 0;
      color: #999;
      line-height: 1.8rem;
      flex-basis:33%;
      a.privacy_link {
        display: inline-block;
      }
      a.author_link {
        display: block;
        margin: 0 auto;
        width: 200px;
      }
      a {
        color: #999;
        font-size: 14px;
      }
      a:hover {
        color: $footer__link-hover;
      }
    }
    .privacy_policy {
      order: 1;
      flex-basis:33%;
      text-align: left;
      padding-left: 40px;
      //display: inline-block;
      .privacy_link {
        cursor: pointer;
        color:#999;
        font-size: 14px;
      }
      .privacy_link:hover {
        color: $desktop_menu_hover_bg;
      }
    }
    @media only screen and (max-width: 700px) {
      display: block;
      .author,
      .privacy_policy,
      .socialmedialink {
        text-align: center;
        padding: 5px 0;
      }
    }
  }
}

// Modal Footer

.modal-footer {
  width: 100%;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  height: 100%;
  min-height: 100vh;
  overflow: auto;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: width .3s ease-in-out;
  .main-wrapper {
    padding: 0 40px;
    max-width: 960px;
    margin: 50px auto;
    h2 {
      text-align: center;
    }
    @media only screen and (max-width: 320px) {
      padding: 0 20px;
    }
  }
}

.privatepolicy-open {
  opacity: 1;
  visibility: visible;
}

.close_button {
  display: block;
  float: right;
  width: 30px;
  height: 60px;
  top: 4px;
  right: 16px;
  position: absolute;
  cursor: pointer;

  .button-x {
    background:$desktop_menu_hover_bg;
    width: 100%;
    height: 2px;
    margin-top:40px;
    position: relative;
    transform: rotate(45deg);
  }
  .button-x::after {
    background:$desktop_menu_hover_bg;
    position: absolute;
    content: "";
    transform: rotate(90deg);
    margin: 0 0 0 0;
    width: 100%;
    height: 2px;
  }
}
.close_modal_bottom {
  text-align: center;
  width: 130px;
  margin: 30px auto;
  padding: 20px;
  border: 1px solid $desktop_menu_hover_bg;
  color: $desktop_menu_hover_bg;
  font-weight: 400;
  display: block;
  transition: all 0.3 ease-in-out;
}
.close_modal_bottom:hover {
  border: 1px solid $desktop_menu_hover_bg;
  background: $desktop_menu_hover_bg;
  color: #fff;
}

#scroll_to_top {
  position: fixed;
  bottom: 20px;
  right: 0;
  background: rgba(0,0,0,0.7);
  visibility: hidden;
  //z-index: 600;
}

.scroll_to_top_show {
  visibility: visible;
}
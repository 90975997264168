
header {
  top:0;
  width: 100%;
  height: 80px;
  display: block;
  z-index: 400;
  background: #fff;
  position: fixed;
  box-shadow: 0 0 7px rgba(0,0,0,0.3);
  .header-wrapper {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    .logo {
      float: left;
      display: inline-block;
      img {
       margin-top: 24px;
        margin-left: 18px;
        width: auto;
        height: 32px;
        @media only screen and (max-width: 560px) {
          margin-top: 32px;
          height: 20px;
        }
        @media only screen and (max-width: 300px) {
          margin-top: 32px;
          height: 16px;
        }

      }
    }
    .main__nav {
      float: right;
      display: inline-block;
      @media only screen and (max-width: 960px) {
        display: none;
      }
    }
    .mobile__nav {
      float: right;
      @media only screen and (max-width: 960px) {
        display: block;
        .button_menu {
          display: block;
          float: right;
          width: 30px;
          height: 40px;
          top: 8px;
          right: 26px;
          cursor: pointer;
          position: absolute;
          .button-x::before,
          .button-x::after,
          .button-x {
            background:$desktop_menu_hover_bg;
            width: 100%;
            height: 2px;
            margin-top:22px;
            position: relative;

            //transition: 0.3s transform ease-in-out;
          }
          .button-x::before {
            width: 80%;
            margin-top: 8px;
            position: absolute;
            content: "";
            //transition: 0.3s transform ease-in-out;
          }
          .button-x::after {
            width: 40%;
            margin-top: 16px;
            position: absolute;
            content: "";
            //transition: 0.3s transform ease-in-out;
          }
        }
      }
    }
  }
}

// mobile nav

nav.mobile-navigation {
  position: absolute;
  z-index: 200;
  background: #fff;
  width: 100%;
  height: auto;
  padding: 40px 40px 40px 40px;
  text-align: right;
  .mobile_logo {
    text-align: left;
    margin-bottom: 10px;

    //img {
    //  width: auto;
    //  height: 40px;
    //  outline: none;
    //}
    img {
      //margin-top: 24px;
      //margin-left: 0px;
      width: auto;
      height: 32px;
      @media only screen and (max-width: 560px) {
        //margin-top: 32px;
        height: 20px;
      }
      @media only screen and (max-width: 300px) {
        //margin-top: 32px;
        height: 16px;
      }

    }
    @media only screen and (min-width: 961px) {
      display: none
    }
  }
  ul.mobile__menu {
    list-style: none;
    padding: 0;
    li {
      padding: 10px 0;
      display: block;
      border-top: 1px solid #EDEDED;
      a {
        display: block;
        font-size: 20px;
        padding-right: 0;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
        @media only screen and (max-width: 400px) {
          font-size: 1rem;
        }
        img {
          top: 6px;
          position: relative;
          left: -4px;
        }
      }
      a:hover {
        position: relative;
        color:$desktop_menu_hover_bg;
        padding-right: 20px;
        //transition: padding 1s ease-in-out;
      }
    }
    //li:hover {
    //  //background: $desktop_menu_hover_bg;
    //  transition: .5s all;
    //
    //
    //}

  }
}


.close_mobile_menu {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  top: 29px;
  right: 34px;
  position: absolute;
  cursor: pointer;
  .button-x {
    background:$desktop_menu_hover_bg;
    width: 100%;
    height: 2px;
    margin-top:32px;
    position: relative;
    transform: rotate(45deg);
    @media only screen and (max-width: 560px) {
      margin-top: 22px;
    }
    @media only screen and (max-width: 300px) {
      margin-top: 12px;
    }
  }
  .button-x::after {
    background:$desktop_menu_hover_bg;
    position: absolute;
    content: "";
    transform: rotate(90deg);
    margin: 0 0 0 -30px;
    width: 100%;
    height: 2px;
  }
  @media only screen and (min-width: 961px) {
    display: none
  }
  @media only screen and (max-width: 300px) {
    //display: none
    top: 34px
  }
}

.modal {
  //display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 500; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  min-height: 100vh;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(255,255,255); /* Fallback color */
  background-color: rgba(255,255,255,1); /* Black w/ opacity */
  opacity: 0;
  visibility: hidden;
  transition:  width .3s ease-in-out;
  @media only screen and (min-width: 960px) {
    display: none

  }
}

.open {
  width: 100%; /* Full width */
  opacity: 1;
  visibility: visible;
}

// desktop nav
ul.main__menu {
  list-style: none;
  padding: 0 0 0 10px;

  li {
    margin-top: 20px;
    margin-right: 10px;
    display: inline-block;
    a.active {
      color:$desktop_menu_hover_bg;
    }
    a {
      text-transform: uppercase;
      text-decoration: none;
      padding: 6px 15px;
      color: #000;
      //line-height: 80px;
      transition: .2s all;
      font-size: 13px;
      font-weight: 500;
      display: inline-block;
    }
    a:hover {
      color:$main__nav-link-color-hover;
    }
  }
  li.active,
  li:hover {
    //transform: skew(-15deg, 0deg);
    height: 40px;
    border-radius: 5px;
    margin-top: 20px;
    background: $desktop_menu_hover_bg;
    a,
    a:hover {
      //transform: skew(15deg, 0deg);
      //display: inline-block;
      color:$main__nav-link-color-hover;
    }
  }

}

.anchor_desktop_menu_link {
  padding-top: 100px;
  margin-top: -100px;
}

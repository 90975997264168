$header-bg: #fff;
$main__nav-link-color: #3366cc;
$main__nav-link-color-hover: #fff;
$main__text-color: #000;
$footer__bg: #EDEDED;
$footer__link-hover: #3366cc;
$desktop_menu_hover_bg: #3366cc;
$contct__p_text: #fff;
$show_more_btn:#ce0000;
$owl_dot_color_active: #3366cc;
$owl_dot_color: #D6D6D6;
$hr-color1: #3366cc;
$hr-color2: #fff;
$offer_bg: #3366cc;
$offer_text: #fff;
$contact_bg: #3366cc;
$contact_text: #fff;
@import 'reset';
@import 'header';
@import 'about_us';
@import 'contact';
@import 'footer';

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700,900&display=swap');

* {
    box-sizing: border-box;
	margin: 0;
	padding: 0;
	-webkit-overflow-scrolling: touch;
	outline: 0;
}

html {
	--scroll-behavior: smooth;
	scroll-behavior: smooth;
}

body {
	color: #555;
	font-family: Montserrat, sans-serif;
	font-weight: 300;
	font-size: 1rem;
}

.modal-open {
    overflow: hidden;
}

h1 {
	font-size: 2rem;
	font-weight: 700;
	line-height: 1.6;
	@media only screen and (max-width: 560px) {
		font-size: 1.4rem;
	}
}

h2 {
	font-size: 2rem;
	font-weight: 700;
	padding:20px 0;
	line-height: 1.4;
	@media only screen and (max-width: 560px) {
		font-size: 1.6rem;
	}
}

h3 {
	font-size: 1.2rem;
	font-weight: 700;
	padding:40px 0 0 0;
	line-height: 1.6;
	@media only screen and (max-width: 560px) {
		font-size: 1rem;
	}
}
h4 {
	font-size: 1.3rem;
	font-weight: 700;
	padding:20px 0;
	line-height: 1.6;
	@media only screen and (max-width: 560px) {
		font-size: 1.1rem;
	}
}

span, a, p {
	font-size: 1rem;
	line-height: 1.6;
	@media only screen and (max-width: 400px) {
		font-size: 0.85rem;
		line-height: 1.5;
	}
}

p {
	padding: 10px 0;
}

ul, ol {
	padding: 10px 0 10px 20px;
	li {
		font-size: 1rem;
		list-style-type: disc;
		padding: 3px 0;
		line-height: 1.6;
		@media only screen and (max-width: 400px) {
			font-size: 0.85rem;
			line-height: 1.5;
		}
	}
}

iframe {
	display: block;
}

hr {
	margin: 20px auto 20px auto;
	width: 80px;
}

hr.dec {
	border: 1px solid $hr-color2;
}

hr.dec_black {
	border: 1px solid $hr-color1;
}

#office1:target {
	content: '';
	position: relative;
	top: -100px;
}

#offer1:target,
#reward1:target,
#online1:target,
#contact1:target {
	content: '';
	position: relative;
	top: 0;
}

.hero__description {
	margin-top: 300px;
	width: 100%;
	height: auto;
	color: #fff;
	background: rgba(0,0,0,.6);
	padding: 40px 80px 40px 40px;
	box-shadow: 0 4px 10px #333;
}

.hero__title {
	font-weight: 900;
}

#aboutus__section {
	content: '';
	display: block;
	overflow: hidden;
	min-height: 100vh;
	background-image: url('../images/header.jpg');
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	@media only screen and (max-width: 1000px) {
		background-attachment: scroll;
		background-position: center center;
	}
	@media only screen and (max-width: 800px) {
		min-height: 60vh;
	}
	.aboutus__container {
			color: #fff;
			margin-top: 100px;
			padding: 20px 40px;
			vertical-align: middle;
			text-align: center;
			width: 100%;
			position: relative;
			@media only screen and (min-width: 800px) {
				margin-top: 300px;
			}
			h1.hero__title {
				font-weight: 700;
				padding: 40px 0 10px 0;
				line-height: 1.3;
				text-shadow: 3px 3px  5px #000;
				font-size: 3em;
				@media only screen and (max-width: 600px) {
					font-size: 2em;
				}
				@media only screen and (max-width: 320px) {
					font-size: 1.3em;
				}
			}
			}
		p.hero__text {
			font-size: 2em;
			text-shadow: 3px 3px  5px #000;
			@media only screen and (max-width: 600px) {
				font-size: 1em;
				}
			}
		}



// office section
#office__section {
	background: #fff;
	padding: 60px 0;
	.office__container {
		margin: 0 auto;
		width: 100%;
		max-width: 1200px;
		.office__left {
			h2 {
				padding: 0;
			}
			p.title {
				color: $desktop_menu_hover_bg;
				font-weight: 700;
				font-size: 1.4rem;
			}
			width: 60%;
			display: inline-block;
			vertical-align: top;
			padding: 0 40px;
			@media only screen and (max-width: 760px) {
				width: 100%;
				display: block;
			}
			.img_offce {
				display: block;
				max-width: 100%;
				margin: 20px 0 ;
				height: auto;
				@media only screen and (min-width: 760px) {
					display: none;
				}
			}
		}
		.office_right {
			width: 38%;
			display: inline-block;
			vertical-align: top;
			padding: 0 40px;
			@media only screen and (max-width: 760px) {
				width: 100%;
				display: block;
			}
			img {
				display: block;
				max-width: 100%;
				height: auto;
			}
			@media only screen and (max-width: 760px) {
				display: none;
			}

		}
	}
}

// offer section
#offer__section {
	background: $offer_bg;
	color: $offer_text;
	padding: 60px 0;
	.offer__container {
		margin: 0 auto;
		width: 100%;
		max-width: 1200px;
		h2.offer__title {
			text-align: center;
			text-transform: uppercase;
		}
		.offer__left {
			width: 48%;
			display: inline-block;
			vertical-align: top;
			padding: 0 40px;
			@media only screen and (max-width: 760px) {
				width: 100%;
				display: block;
			}
		}
		.offer__right {
			width: 48%;
			display: inline-block;
			vertical-align: top;
			padding: 0 40px;
			@media only screen and (max-width: 760px) {
				width: 100%;
				display: block;
			}
		}
	}
}

// Publication
#reward__section,
#online__section {
	background: #fff;
	padding: 60px 0 80px 0;
	.reward__container,
	.online__container {
		margin: 0 auto;
		padding: 0 40px;
		width: 100%;
		max-width: 1200px;
		h2 {
			text-align: center;
		}
		h3, h4, p {
			text-align: left;
		}
		h3 {
			padding: 20px 0;
		}
	}
	.twocol__container {
		margin: 0 auto;
		width: 100%;
		max-width: 1200px;
		h2 {
			text-align: center;
			text-transform: uppercase;
		}
		.offer__left {
			width: 48%;
			display: inline-block;
			vertical-align: top;
			padding: 0 40px;
			@media only screen and (max-width: 760px) {
				width: 100%;
				display: block;
			}
		}
		.offer__right {
			width: 48%;
			display: inline-block;
			vertical-align: top;
			padding: 0 40px;
			@media only screen and (max-width: 760px) {
				width: 100%;
				display: block;
			}
		}
	}
}

// Publication
#referer__section {
	background: #fff;
	padding: 60px 0 80px 0;
	.referer__container {
		margin: 0 auto;
		padding: 0 40px;
		width: 100%;
		max-width: 960px;
		h2, p {
			text-align: center;
		}
	}
}

// Parallax
.break__section {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	text-shadow: 0 0 5px #000;
	vertical-align: top;
	img {
		margin: 0;
		padding: 0;
		vertical-align: top;
		display: block;
		max-width: 100%;
		height: auto;
	}
}
.parallax {
	height: 70vh;
	opacity: 1;
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	@media only screen and (max-width: 1000px) {
		background-attachment: scroll;
		background-position: center center;
		background-size: cover;
	}
}

.bg-offer {
	background-image: url('../images/offer.jpg');
}
.bg-online {
	background-image: url('../images/online.jpg');
	background-position: right top;
	//background-attachment: scroll;
	background-size: 100%;
	height: 50vh;
	max-height:100%;
	@media only screen and (max-width: 600px) {
		background-size: 100%;
		height:10vh !important;
	}
	@media only screen and (max-width: 400px) {
		background-size: 100%;
		height: auto ;
	}
}
.bg-reward {
	background-image: url('../images/reward.jpg');
}
.bg-contact {

	background-position: right bottom;
	background-image: url('../images/contact.jpg');
	@media only screen and (max-width: 600px) {
		background-size: 100%;
		//height: 60vh;
	}
}

.center-text {
	text-align: center!important;
}

